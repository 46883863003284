import React from "react";
import styled from "styled-components";
import { NavLink } from "../../components/library/Links";
import { Text } from "../../components/library/Text";

const Section = styled.div`
  height: 585px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #090c0e;
`;

const Wrapper = styled.div`
  height: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Tagline = styled.p`
  padding: 0 1.0625rem;
  font-family: Quarto;
  font-weight: bold;
  font-size: 55px;
  line-height: 66px;
  color: white;
`;

const Subtext = styled(Text)`
  color: white;
  margin-bottom: 54px;
`;

const ShopNowButton = styled(NavLink)`
  background-color: #def0fb;
  font-family: "Cera Round Pro";
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  text-align: center;

  &&& {
    color: #090c0e;
    padding: 38px 125px;
  }

  @media screen and (max-width: 768px) {
    &&& {
      padding: 38px 50px;
    }
  }
`;

const ShopNow = () => (
  <Section>
    <Wrapper>
      <Tagline>Our Mission</Tagline>
      <Subtext>Give shine to Black men, everyday, everywhere.</Subtext>
      <ShopNowButton to="/products">Shop Now</ShopNowButton>
    </Wrapper>
  </Section>
);

export default ShopNow;
