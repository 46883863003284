import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import IngredientFeature from "../routes/formula/IngredientFeature";
import LandingSection from "../routes/formula/LandingSection";
import ShopNow from "../routes/formula/ShopNow";

const Formula = () => (
  <Layout>
    <Seo title="Formula" />
    <LandingSection />
    <IngredientFeature />
    <ShopNow />
  </Layout>
);

export default Formula;
