import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Header } from "../../components/library/Text";

const Section = styled.section`
  position: relative;
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 36px 15px 114px;
`;

const Feature = styled(Header)`
  color: #090c0e;
`;

const Highlight = styled.div`
  font-family: Quarto;
  font-size: 36px;
  font-style: normal;
  font-weight: bold;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);

  span {
    color: #93b1c3;
  }
`;

const Line = styled.div`
  position: absolute;
  left: 50%;
  bottom: -87.5px;
  height: 175px;
  border-left: 3px solid #bfebf8;
  box-sizing: border-box;
  transform: translateX(-50%);
`;

const Vid = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 94px;
  font-family: Quarto;
  font-style: normal;
  font-weight: normal;
  font-size: 66px;
  line-height: 1.2;
  color: #090c0e;

  img,
  video,
  video[poster] {
    height: 100%;
    width: 100%;
    max-height: 560px;
    object-fit: cover;
  }

  video {
    z-index: -1;
  }

  img {
    opacity: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const VideoBox = ({ src }: { src: string }) => {
  const imgRef = useRef<HTMLImageElement>();
  const baseUrl = "https://static.wavyformula.com";

  const onLoad = () => {
    imgRef.current.style.opacity = "1";
  };

  useEffect(() => {
    imgRef.current.src = imgRef.current.dataset.src;
  }, []);

  return (
    <Vid>
      <video autoPlay loop muted playsInline poster={`${baseUrl}/images/${src}-frame.gif`}>
        <source src={`${baseUrl}/videos/${src}-opt.webm`} type="video/webm" />
        <source src={`${baseUrl}/videos/${src}-opt.mp4`} type="video/mp4" />
      </video>
      <img ref={imgRef} onLoad={onLoad} data-src={`${baseUrl}/images/${src}-opt.gif`} />
    </Vid>
  );
};

const LandingSection = () => {
  return (
    <Section>
      <VideoBox src="apply-product" />
      <Feature>Formulated for your Hair</Feature>
      <Highlight>
        All of our products are created with ingredients like <span>ginseng</span>,{" "}
        <span>bamboo</span>, and <span>coconut extract</span> that are found in nature to maximize
        results for curly & textured hair
      </Highlight>
      <Line />
    </Section>
  );
};

export default LandingSection;
