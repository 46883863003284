import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { Bamboo, CoconutOil, Ginseng } from "../../components/library/Icons";

const Section = styled.div`
  background-color: #bfebf8; ;
`;

const Wrapper = styled.div`
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 122px 15px;
`;

const ImgContainer = styled.div`
  border: 4px solid #8fd4f1;
  box-shadow: 20px 20px #8fd4f1;

  @media screen and (max-width: 400px) {
    box-shadow: 10px 10px #8fd4f1;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    max-height: 343px;
    max-width: 343px;
  }
`;

const Info = styled.div`
  padding-left: 34px;

  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-top: 40px;
  }
`;

const SVG = styled.div`
  margin-bottom: 14px;
`;

const Ingredient = styled.p`
  font-family: "Gill Sans";
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.8);
`;

const IngredientInfo = styled.p`
  font-family: "Gill Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 58px;

  :last-child {
    margin-bottom: 0;
  }
`;

const IngredientFeature = () => {
  return (
    <Section>
      <Wrapper>
        <Container fluid>
          <Row>
            <Col md={6}>
              <ImgContainer>
                <StaticImage
                  src="https://static.wavyformula.com/images/wavy-coconut.jpg"
                  alt="Coconut"
                />
              </ImgContainer>
            </Col>
            <Col md={6}>
              <Info>
                <SVG>
                  <Ginseng />
                </SVG>
                <Ingredient>Ginseng</Ingredient>
                <IngredientInfo>
                  Ginseng is made up of compounds called ginsenosides which have been proven to
                  promote hair growth due to their anti-oxidation effects that alter the hair growth
                  cycle. Ginsenosides act as anti-aging agents against UV ray exposure that causes
                  hair loss.
                </IngredientInfo>
                <SVG>
                  <Bamboo />
                </SVG>
                <Ingredient>Bamboo</Ingredient>
                <IngredientInfo>
                  Bamboo is rich in silica and can promote hair growth, helps strengthen hair, and
                  gives hair a healthy, bouncy texture.
                </IngredientInfo>
                <SVG>
                  <CoconutOil />
                </SVG>
                <Ingredient>Coconut Extract</Ingredient>
                <IngredientInfo>
                  Helps stop dryness by locking in moisture and provides shine without being greasy.
                </IngredientInfo>
              </Info>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default IngredientFeature;
